export const color = {
    primary: '#414041',
    secondary: '#ffffff',
    tertiary: '#ffffff',
    headerLogo: '#000000',

    blackOne: '#000',
    blackTwo: '#43484D',

    buttonNextBgColor: '#000000',
    buttonNextTextColor: '#ffffff',
    buttonNextMobileColor: '#000000',
    buttonNextMobileTextColor: '#000',
    buttonNextBorderColor: '#000000',
    buttonNextMobileBorderColor: '#000000',
    buttonNextHoverBgColor: '#ffffff',
    buttonNextHoverTextColor: '#000000',
    buttonNextHoverBorderColor: '#000000',

    buttonBackBgColor: '#ffffff',
    buttonBackTextColor: '#000000',
    buttonBackMobileBgColor: '#000000',
    buttonBackMobileTextColor: '#000',
    buttonBackBorderColor: '#000000',
    buttonBackMobileBorderColor: '#000000',
    buttonBackHoverBgColor: '#000000',
    buttonBackHoverTextColor: '#fff',
    buttonBackHoverBorderColor: '#000000',

    exchangeSelectBtnTextColor: '#ffffff',
    exchangeSelectBtnBgColor: '#000000',
    exchangeSelectBtnBorderColor: '#000000',
    exchangeSelectBtnBorderSize: '2px',
    exchangeSelectBtnSVGColor: '#ffffff',

    exchangeDontSelectBtnTextColor: '#ffffff',
    exchangeDontSelectBtnBgColor: '#000000',
    exchangeDontSelectBtnBorderColor: '#000000',
    exchangeDontSelectBtnBorderSize: '2px',
    exchangeDontSelectBtnSVGColor: '#ffffff',

    exchangeRemoveSelectBtnTextColor: '#ffffff',
    exchangeRemoveSelectBtnBgColor: '#000000',
    exchangeRemoveSelectBtnBorderColor: '#000000',
    exchangeRemoveSelectBtnBorderSize: '2px',
    exchangeRemoveSelectBtnSVGColor: '#ffffff',

    flowButtonSelectedTextColor: '#ffffff',
    flowButtonSelectedBgColor: '#000000',
    flowButtonSelectedBorderColor: '#000000',

    flowButtonOriginalTextColor: '#000000',
    flowButtonOriginalBgColor: '#ffffff',
    flowButtonOriginalBorderColor: '#000000',

    grayOne: '#E6E6E6',
    grayTwo: '#f7f7f7',
    grayThree: '#666',
    grayFour: '#C4C4C4',
    grayFive: '#DCDCDC',
    graySix: '#A6A6A6',
    graySeven: '#F1F1F1',
    grayEight: '#F5F5F5',
    grayTen: '#666666',
    grayEleven: '#EDEDED',
    grayTwelve: '#E6E6E6',
    grayThirteen: '#DADADA',
    grayFourteen: '#696969',
    grayFifteen: '#E5E5E5',
    graySixteen: '#E2E2E2',
    graySeventeen: '#D3D3D3',
    grayEighteen: '#5E5E5E',
    grayNineteen: '#A1A1A1',

    greenTwo: '#D7F2EA',
    greenThree: '#D7F2EA',
    greenFour: 'rgba(45,205,178,0.2)',

    redOne: '#EA0000',
    redTwo: '#F32727',
}
