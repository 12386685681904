import styled, {css} from 'styled-components'
import {device, merchantThemes} from '../../../../components/Variable'
import {rem} from "../../../../utils/common";

export const FooterWrapper = styled.footer`
    background-color: ${({theme}) => theme.color.footerBg};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    text-align: center;
	
    ${p => p.theme.name === merchantThemes.douchebags && css`
       box-shadow: none;
       z-index: 1;
    `}
    
    ${p => p.theme.name === merchantThemes.toteme && css`
       border-top: ${rem(1)} solid ${({theme}) => theme.color.blackOne}
    `}
    
    ${p => p.theme.name !== merchantThemes.toteme && css`
       box-shadow: 0 0 ${rem(1)} ${({theme}) => theme.color.blackOne};
    `}

    ${p => p.theme.name === merchantThemes.malene_birger && css`
       box-shadow: 0 ${rem(-1)} ${rem(10)} ${rem(2)} rgba(54, 48, 46, 0.1);
       background-color: ${({ theme }) => theme.color.secondary};
    `}
`

export const FooterLogoWrapper = styled.div`
    padding: ${rem(10)} ${rem(0)} ${rem(10)};
    max-width: ${rem(150)};
    margin: 0 auto;
    
    svg {
      width: 100%;
      fill: ${({theme}) => theme.color.headerLogo}
    }
    
    ${p => p.theme.name === merchantThemes.douchebags && css`
       svg {
          width: 100%;
          fill: ${({theme}) => theme.color.primary}
        }
    `}
    
    @media (${device.tablet}) {
		padding: ${rem(5)} ${rem(0)};
		max-width: ${rem(100)};
	}
	
`

export const FooterLinksWrapper = styled.div`
    ${p => p.theme.name === merchantThemes.marysia && css`
      padding: ${rem(21, 0)};
    `}
    
    ${p => p.theme.name === merchantThemes.toteme && css`
      padding: ${rem(21, 0)};
    `}
`

export const FooterLink = styled.a`
    text-decoration: none;
    
    ${p => p.theme.name === merchantThemes.toteme && css`
      color: ${({theme}) => theme.color.primary}
    `}
    & svg {
      fill: ${({theme}) => theme.color.primary}
    }
    &:hover {
      text-decoration: underline;
    }
`