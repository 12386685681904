import styled, { css } from 'styled-components'
import { device, merchantThemes } from '../../../../components/Variable'
import { rem } from '../../../../utils/common'

export const SocialNetworksViewWrapper = styled.div`
	background-color: ${({ theme }) => theme.color.secondary};
	box-shadow: 0 ${rem(-1)} ${rem(14)} ${rem(2)} rgba(0, 0, 0, 0.2);
	padding: ${rem(40, 25, 30)};
	width: 100%;
	border-radius: ${rem(8)};
	position: relative;
	z-index: 4;
	transition: top 0.5s;
	top: 0;
	
	${p => p.theme.name === merchantThemes.woodwood && css`
      box-shadow: 0 ${rem(1)} ${rem(4)} ${({theme}) => theme.color.blackOne};
    `}
	
	@media (${device.tablet}) {
		position: relative;
		top: 0;
		padding: ${rem(22)};
	}
	
	@media (${device.mobileL}) {
		padding: ${rem(26, 16, 16)};
	}
`

export const SocialNetworksViewHeader = styled.div`
	position: relative;
`

export const SocialNetworksViewTitle = styled.div`
	text-align: center;
	font-size: ${rem(26)};
	margin-bottom: ${rem(40)};
	position: relative;
	@media (${device.tablet}) {
		margin-bottom: ${rem(20)};
	}
	@media (${device.mobileL}) {
		margin-bottom: ${rem(26)};
	}
	
	${p => p.theme.name === merchantThemes.bymalina && css`
        font-size: ${rem(25.74)};
        width: ${rem(195)};
        margin: 0 auto ${rem(10)} auto;
        line-height: ${rem(20)};
    `}
    
    ${(p) => p.theme.name === merchantThemes.toteme && css`
        font-size: ${rem(15)};
        letter-spacing: ${rem(0.06)};
        line-height: ${rem(20)};
    `}
	
`

export const SocialNetworksViewBody = styled.div`
	//border: ${rem(1)} solid ${({theme}) => theme.color.blackOne};
	//background: ${({theme}) => theme.color.grayTwo};
	//border-radius: ${rem(6)};
	padding: ${rem(15)};
	
	@media (${device.tablet}) {
		padding: ${rem(6)};
	}
	
	@media (${device.mobileL}) {
		padding: 0;
	}
`

export const SocialNetworksViewItem = styled.div`
	text-align: center;
	margin-bottom: ${rem(10)};
`

export const SocialNetworksViewBtn = styled.div`
	text-align: center;
	position: relative; 
	border-radius: ${rem(2)};
	font-size: ${rem(16)};
	padding: ${rem(10, 0)};
	cursor: pointer;
	width: ${rem(350)};
	margin: 0  auto;
	opacity: 0.8;
	
	${(p) => p.theme.name === merchantThemes.toteme && css`
        font-size: ${rem(15)};
        letter-spacing: ${rem(0.06)};
        line-height: ${rem(20)};
    `}
	
	@media (${device.mobileL}) {
		width: 100%;
	}
	
	&.returndao-google {
	  background-color: ${({theme}) => theme.color.socialGoogleBgColor};
	  color: ${({theme}) => theme.color.socialGoogleTextColor};
	  box-shadow: 0 0 ${rem(2)} ${({theme}) => theme.color.blackOne};
	  
	  > svg {
	    margin-top: ${rem(-12)};
	  }
	}
	
	&.returnado-facebook {
	  background-color: ${({theme}) => theme.color.socialFacebookBgColor};
	  color: ${({theme}) => theme.color.socialFacebookTextColor};
	  
	  > svg {
	    margin-top: ${rem(-13)};
	    fill: ${({theme}) => theme.color.secondary};
	    width: ${rem(26)};
	    height: ${rem(26)};
	  }
	}
	
	&.returnado-email {
	  background-color: ${({theme}) => theme.color.socialEmailBgColor};
	  color: ${({theme}) => theme.color.socialEmailTextColor};
	  margin: 0 auto ${rem(20)} auto;
	  
	  > svg {
	    margin-top: ${rem(-12)};
	    fill: ${({theme}) => theme.color.secondary}
	  }
	}
	
	&:hover {
	  opacity: 1;
	}
	
	> svg {
	  position: absolute;
	  top: 50%;
	  left: ${rem(15)}
	}
	
`

