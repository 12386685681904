export const color = {
	primary: '#C4AA85',
	secondary: '#ffffff',
	tertiary: '#ffffff',
	headerLogo: '#000',
	footerBg: '#ffffff',

	blackOne: '#000',
	blackTwo: '#43484D',

	grayOne: '#E6E6E6',
	grayTwo: '#f7f7f7',
	grayThree: '#666',
	grayFour: '#C4C4C4',
	grayFive: '#DCDCDC',
	graySix: '#A6A6A6',
	graySeven: '#F1F1F1',
	grayEight: '#F5F5F5',
	grayTen: '#666666',
	grayEleven: '#EDEDED',
	grayTwelve: '#E6E6E6',
	grayThirteen: '#DADADA',
	grayFourteen: '#696969',
	grayFifteen: '#E5E5E5',
	graySixteen: '#E2E2E2',
	graySeventeen: '#D3D3D3',
	grayEighteen: '#5E5E5E',
	grayNineteen: '#A1A1A1',

	greenTwo: '#D7F2EA',
	greenThree: '#D7F2EA',
	greenFour: 'rgba(45,205,178,0.2)',

	redOne: '#EA0000',
	redTwo: '#F32727',

	socialFacebookBgColor: '#517dbd',
	socialFacebookTextColor: '#ffffff',
	socialGoogleBgColor: '#ffffff',
	socialGoogleTextColor: '#687582',
	socialEmailBgColor: '#414041',
	socialEmailTextColor: '#ffffff'
}
