import styled, {css} from 'styled-components'
import {rem} from '../../utils/common'
import {device, merchantThemes} from '../Variable'
import planetIcon from '../../assets/icons/markets-planet-icon.png'

export const MultipleComboBoxSelectStyle = styled.div`
    position: relative;
	display: block;
	max-width: ${rem(160)};
	width: 100%;
	margin-right: ${rem(36)};
	
	@media (${device.mobileL}) {
	    position: relative;
	    max-width: ${rem(149)};
		width: 50%;
		z-index: 6;
		padding: 0;
	}
	.react-select {
		&__control {
			width: 100%;
			max-height: ${rem(32)};
			
			${p => p.theme.name === merchantThemes.toteme && css`
              border-radius: 0;
              outline: none;
              border-color: ${({theme}) => theme.color.primary};
              box-shadow: none;
              
              &:hover {
                border-color: ${({theme}) => theme.color.primary};
              }
            `}
            ${p => p.theme.name === merchantThemes.indiska && css`
              text-transform: uppercase;
            `}
			
			@media (${device.mobileL}) {
				width: 100%;
				margin: 0 auto;
			}
		}
		&__indicators {
			cursor: pointer;
			height: 100%;
		}
		&__indicator-separator {
			display: none;
		}
		&__dropdown-indicator {
		}
		&__value-container {
		  height: 100%;
		}
		&__single-value {
		    white-space: nowrap;
			@media (${device.mobileL}) {
				font-size: ${rem(14)};
				
				${(p) => p.theme.name === merchantThemes.toteme && css`
                    font-size: ${rem(15)};
                    letter-spacing: ${rem(0.06)};
                    line-height: ${rem(20)};
                `}
			}
		}

		&__menu {
		  margin: ${rem(1, 0, 0, 0)};
		  border-radius: 0;
		  min-width: ${rem(140)};
		  
		  ${p => p.theme.name === merchantThemes.toteme && css`
            border: 1px solid ${({theme}) => theme.color.primary};
            border-radius: ${rem(16)};
            box-shadow: none;
          `}
		}

		&__option {
		}
	}
`

export const MultipleComboBoxSelectStyleIcon = styled.div`
    position: absolute;
    background: url(${planetIcon}) no-repeat;
    background-size: contain;
    width: ${rem(26)};
    height: ${rem(24)};
    left: ${rem(-23)};
    top: 50%;
    margin: ${rem(-12)};
    
    @media (${device.mobileL}) {
	    display: none;
	}
`