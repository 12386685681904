import React, { useState, useEffect } from 'react'
import ReactHtmlParser from "react-html-parser";
import {
	AccordionList,
	Accordion,
	Header,
	Title,
	AccordionToggle,
	Editor,
	Collapse,
} from './style'
import { Icon } from '../Icon'
import {useSelector, useDispatch} from "react-redux";
import {getPolicyReq} from "../../containers/Main/thunk";

const Accordions = ({ list, isOpenByDefault }) => {
	const [isOpen, setIsOpen] = useState({
		idx: null,
		status: true,
	})
	const { shopId, locale } = useSelector((state) => state.root);
	const dispatch = useDispatch()
	const { terms } = useSelector((state) => state.main);

	useEffect(() => {
		if (typeof isOpenByDefault !== 'undefined') {
			setIsOpen({idx: isOpenByDefault, status: true})
		}
	}, [isOpenByDefault]);
	
	useEffect(() => {
		dispatch(getPolicyReq(shopId, locale));
	}, [shopId, locale, dispatch])  // eslint-disable-line react-hooks/exhaustive-deps

	const listData = list && list.length ? list : terms && terms.length ? terms : [];

	const toggle = (idx) => {
		if (idx === isOpen.idx && isOpen.status) {
			setIsOpen({
				idx,
				status: false,
			})
		} else {
			setIsOpen({
				idx,
				status: true,
			})
		}
		setTimeout(()=>{
			let selector = document.querySelector('.ps__rail-y')
			selector.setAttribute('style', 'top: 0px')
		},300)

	}

	return (
		<AccordionList>
			{!!listData && !!listData.length && listData.length !== 1 ? (
				listData.map((item, idx) => (
				<Accordion key={idx}>
					<Header onClick={() => toggle(idx)}>
						<AccordionToggle isOpen={idx === isOpen.idx && isOpen.status}>
							<Icon
								component={
									idx === isOpen.idx && isOpen.status
										? 'minusRadial'
										: 'plusRadial'
								}
							/>
						</AccordionToggle>
						<Title>{item.title}</Title>
					</Header>
					<Collapse isOpen={idx === isOpen.idx && isOpen.status}>
						<Editor>{ReactHtmlParser(item.text)}</Editor>
					</Collapse>
				</Accordion>
			))) : (
				listData.map((item, idx) => (
					<Accordion key={idx} singleItem>
						<Title>{item.title}</Title>
						<Editor>{ReactHtmlParser(item.text)}</Editor>
					</Accordion>
					)
				)
			)}
		</AccordionList>
	)
}
export default Accordions
