export const fonts = (family) => {
    switch(family) {
        case 'secondary':
            return {
                fontFamily: 'PTSerif',
                fontFamilyTitle: 'PTSerif',
            }
        default:
            return {
                fontFamily: 'BatonTurbo',
                fontFamilyTitle: 'BatonTurbo',
            };
    }
}