import React from 'react'
import {LoggedInWrapper, LoggedInHeader, LoggedInTitle, LoggedInLogout, LoggedInBody, LoggedInP} from './style'
import {FormattedMessage} from "react-intl";
import {ButtonFull} from "../../../../components/Form/Button";
import {Icon} from "../../../../components/Icon";
import {useSelector, useDispatch} from "react-redux";
import {useLocation, useHistory} from "react-router";
import {setLoggedOut} from "../../slices";
import {clearReturnadoToken} from "../../../../store/root-slices";
import {removeUrlParameter} from "../../../../utils/common";

const LoggedInView = ({
        openWidget = () => {}
    }) => {
    const dispatch = useDispatch();
    const isReconversion = useSelector(
        (state) => state.root.shopProperties['reconversion.disabled']
    )
    const location = useLocation()
    const history = useHistory()

    const logout = () => {
        removeUrlParameter(location, history, 'returnadoToken')
        dispatch(setLoggedOut());
        dispatch(clearReturnadoToken());
        window.rWidget.destroy();
    }

    return (
        <LoggedInWrapper>
            <LoggedInHeader>
                <LoggedInLogout onClick={()=>{
                    logout()
                }}>
                    <Icon component={'logout'} />
                </LoggedInLogout>
                <LoggedInTitle>
                    <FormattedMessage
                        id='login.intro.header'
                        defaultMessage='Login with email'
                    />
                </LoggedInTitle>
            </LoggedInHeader>
            <LoggedInBody>
                <LoggedInP>
                    {
                        isReconversion === 'true'
                        ?
                        <FormattedMessage
                            id='login.intro.reconversion.enabled.text'
                            defaultMessage='Through Returnado you can register your return and receive help, sign in with the email address you used when ordering.'
                        />
                        :
                        <FormattedMessage
                            id='login.intro.reconversion.disabled.text'
                            defaultMessage='Through Returnado you can register a return or find replacement products, sign in with the email address you used when ordering.'
                        />
                    }
                </LoggedInP>
                <ButtonFull
                    w100
                    onClick={openWidget}
                    className='mb-4 logged-in-control'
                    iconPosition={'left'}
                >
                    <FormattedMessage
                        id='login.form.login.button.text'
                        defaultMessage='VIEW ORDER'
                    />
                </ButtonFull>
            </LoggedInBody>
        </LoggedInWrapper>
    )
}
export default LoggedInView
